import React from 'react';

import Loading from '../components/Loading';
import { Redirect, useLocation } from '@reach/router';
import translations from '../translations/main.json';
import { parse } from 'qs';

const SsoLogin = () => {
  const { search } = useLocation();

  if (search.includes('sso_success')) {
    const { redirect } = parse(search, { ignoreQueryPrefix: true });

    localStorage.setItem('isLoggedIn', 'true');
    return (
      <Redirect
        to={redirect ?? translations['cc4.seo.route.account']}
        noThrow
      />
    );
  }

  return (
    <div
      className="flex items-center align-center justify-center"
      style={{ height: '100vh' }}
    >
      <Loading />
    </div>
  );
};

SsoLogin.propTypes = {};

export default SsoLogin;
